import Api from './Api';

const all = (payload) => {
    const path = '/mail-boxes';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const getByOne = (payload) => {
    const path = '/mail-box/find-by-one';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;
    return Api.getRequest({
        endpoint
    });
};


const store = (payload) => {
    return Api.postRequest({
        endpoint: '/mail-box/store',
        data: payload
    });
};

const emailFilter = (payload) => {
    return Api.postRequest({
        endpoint: '/mail-box/email-filter',
        data: payload
    });
};

const update = (payload) => {
    return Api.postRequest({
        endpoint: '/mail-box/update',
        method: 'PUT',
        data: payload
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/mail-box/destroy/${payload.id}`,
    });
};

const softDelete = (payload) => {
    return Api.deleteRequest({
        endpoint: `/mail-box/soft-delete/${payload.id}`,
    });
};

export default {
    all,
    store,
    update,
    destroy,
    softDelete,
    getByOne,
    emailFilter
};